import React from "react";
import { SERVER_URL } from "../config/config.js";
import "./biltyReport.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportTable from "./ReportTable.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReportExcel from "./ReportExcel.js";
import { useLocation, useHistory } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "./popup.css";
let ex_data = [];

function PodReciveReport({ sessionObject }) {
  const history = useHistory();
  const branchId = sessionObject.sessionVariables.branch_id;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Bilty No",
        accessor: "bilty_no",
        canFilter: true,
        width: "80px",
        minWidth: "80px",
      },
      {
        Header: "Bilty date",
        accessor: "bilty_date",
        canFilter: true,
        width: "100px",
        minWidth: "80px",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Station From",
        accessor: "station_from",
        canFilter: true,
      },
      {
        Header: "Station To",
        accessor: "station_to",
        width: "150px",
        minWidth: "80px",
        canFilter: true,
      },
      {
        Header: "Consignor Name",
        accessor: "consignor_name",
        width: "300px",
        minWidth: "80px",
        canFilter: true,
      },
      {
        Header: "Consignee Name",
        accessor: "consignee_name",
        width: "250px",
        minWidth: "80px",
        canFilter: true,
      },
      {
        Header: "Pkgs",
        accessor: "no_of_package",
        width: "250px",
        minWidth: "80px",
        canFilter: true,
      },
      {
        Header: "POD Owned By",
        accessor: "pod_owned_by",
        width: "250px",
        minWidth: "80px",
        canFilter: true,
      },
      // {
      //   Header: "POD Upload date",
      //   accessor: "created_date",
      //   canFilter: true,
      //   width: "100px",
      //   minWidth: "80px",
      //   Cell: ({ value }) => formatDate(value),
      // },
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const download_ref = React.useRef(null);
  const sortIdRef = React.useRef(0);
  const [dateState, setDateState] = React.useState({
    date_from: new Date(),
    date_to: new Date(),
    all_branch: "2",
  });
  const [finalInput, setFinalInput] = React.useState({});
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

//   const [podImageOpen, setPodImageOpen] = useState(false);
//   const [imageUrl, setImageUrl] = useState("");
//   const [rotationAngle, setRotationAngle] = useState(0); 
//   const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    const reportType = query.get("report_type");
    console.log("data is : ", { data });
    // myForm.setPageStateByField("voucher_type", voucher);
    setDateState({
      ...dateState,
      ["report_type"]: reportType,
    });
  }, []);

  const fetchData = React.useCallback(
      async ({ pageSize, pageIndex, sortBy, customFilters, dateObject }) => {
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.
  
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;
        console.log("12323", sortBy, customFilters, fetchId);
        for (let key in customFilters){
          console.log("Key", customFilters[key].toLowerCase(), key)
          if (customFilters[key].toLowerCase() == "none"){
            customFilters[key] = null
          }
        }
        // Set the loading state
        setLoading(true);
  
        if (fetchId === fetchIdRef.current) {
          console.log("Date state", dateObject);
  
  
          let newObject = {}
          // if (dateObject.report_type == "bilty"){
          // newObject.created_from = String(
          //   sessionObject.sessionVariables.branch_id
          // );
            if (dateObject.all_branch == "2") {
              newObject.created_from = parseInt(
                sessionObject.sessionVariables.branch_id
              );
            }
          // }
          if (dateObject.report_type == "inward"){
            newObject.owned_by = String(
              sessionObject.sessionVariables.branch_id
            );
            // if (dateObject.in_stock == "N") {
            //   newObject.created_from = String(
            //     sessionObject.sessionVariables.branch_id
            //   );
            // } else {
            //   newObject.owned_by = String(
            //     sessionObject.sessionVariables.branch_id
            //   );
            // }
          }
  
  
          newObject = {...newObject,...customFilters}
  
          let inputData = {
            paginate: { number_of_rows: pageSize, page_number: pageIndex + 1 },
            sort_fields: sortBy,
            filter_fields: newObject,
            date_dict: {
              date_from: dateObject.date_from,
              date_to: dateObject.date_to,
            },
          };

        let response = await fetch(SERVER_URL + "/report/get_pod_receive_report", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData),
        });
        let resp = await response.json();
        console.log("data received: ", resp);

        if (resp["data"] && "total_rows" in resp) {
            // let totalAmt = 0;
            // resp["data"].forEach(row => {
            //     totalAmt += row.total_amount;
            // })
            setData(resp["data"]);
            // setTotalAmount(totalAmt);
            setPageCount(Math.ceil(resp["total_rows"] / pageSize));
        }
        setLoading(false);
      }

      // We'll even set a delay to simulate a server here
      //   setTimeout(() => {
      //     // Only update the data if this is the latest fetch
      //     if (fetchId === fetchIdRef.current) {
      //       const startRow = pageSize * pageIndex;
      //       const endRow = startRow + pageSize;
      //     //   setData(serverData.slice(startRow, endRow));
      //       console.log("data", data);
      //       // Your server could send back total page count.
      //       // For now we'll just fake it, too
      //       setPageCount(Math.ceil(serverData.length / pageSize));

      //       setLoading(false);
      //     }
      //   }, 1000);
    },
    []
  );

  // const handleViewClick = (cell) => {
  //   console.log("---", cell[0].value);
  //   history.push("/pod_statement", cell[0].value);
  // };

  // const handlePreviewPod = async (row) => {
  //   const biltyId = row.original.bilty_id;
  //   const apiUrl = `${SERVER_URL}/pod_image/?bilty_id=${biltyId}`;
  
  //   setPodImageOpen(true);
  
  //   setLoadingImage(true);
  //   setImageUrl("");
  
  //   try {
  //     const response = await fetch(apiUrl);
  
  //     if (response.ok) {
  //       const imageBlob = await response.blob();
  //       const imageUrl = URL.createObjectURL(imageBlob);
  //       setImageUrl(imageUrl);
  //     } else {
  //       console.error("Failed to load the POD image.");
  //       setImageUrl("");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching POD image:", error);
  //     setImageUrl("");
  //   } finally {
  //     setLoadingImage(false);
  //   }
  // };
  
  // const handleRotate = () => {
  //   setRotationAngle((prevAngle) => (prevAngle + 90) % 360);
  // };

  // const handleDownloadPod = async (row) => {
  //   try {
  //     const biltyId = row.original.bilty_id; 
  //     if (!biltyId) {
  //       console.error("Bilty ID is undefined!");
  //       return;
  //     }
  
  //     const apiUrl = `${SERVER_URL}/pod_image/?bilty_id=${biltyId}`;
  //     const response = await fetch(apiUrl);
  
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  
  //     const blob = await response.blob();
  //     const downloadLink = document.createElement('a');
  //     const blobUrl = URL.createObjectURL(blob);
  
  //     downloadLink.href = blobUrl;
  //     downloadLink.download = `pod_image_${biltyId}.jpg`;
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //     URL.revokeObjectURL(blobUrl);
  //   } catch (error) {
  //     console.error('Fetch error:', error);
  //   }
  // };
  

  return (
    <div className="page-marfatiya-wise">
      <div className="report-bilty">
        <div className="form-header">POD received Report</div>
        <div className="report-bilty-table-container">
          <div className="report-field-row ">
            <div>
              From Date:{" "}
              <DatePicker
                dateFormat="dd-MM-yyy"
                selected={dateState.date_from}
                onChange={(date) =>
                  setDateState({
                    ...dateState,
                    ["date_from"]: date,
                  })
                }
                
              />
            </div>
            <div>
              To Date:{" "}
              <DatePicker
                dateFormat="dd-MM-yyy"
                selected={dateState.date_to}
                onChange={(date) =>
                  setDateState({
                    ...dateState,
                    ["date_to"]: date,
                  })
                }
                
              />
            </div>
          </div>
          <div>
            <label>All Branch?</label>
            <select
              onChange={(e) => {
                const { name, value } = e.target;
                setDateState({
                  ...dateState,
                  [name]: value,
                });
              }}
              // ref={(a) => myForm.storeInputReferenceForSelect(a, "is_crossing")}
              // disabled={checkDisabledCondition({ name: "is_crossing" })}
              value={dateState["all_branch"]}
              name="all_branch"
              // onKeyPress={(a) => {
              //   if (a.key == "Enter") {
              //     a.preventDefault();
              //     if (
              //       myForm.pageState.is_crossing == "N" ||
              //       myForm.pageState.is_crossing == "n"
              //     ) {
              //       myForm.makeFocusOnParticularField("bilty_no");
              //       return;
              //     } else {
              //       myForm.makeFocusOnParticularField("transporter_name");
              //       return;
              //     }
              //   }
              // }}
            >
              <option value="1" key="1">
                Yes
              </option>
              <option value="2" key="2">
                No
              </option>
            </select>
          </div>

          <ReportTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            dateObject={dateState}
            loading={loading}
            pageCount={pageCount}
            // canView={true}
            // canPreview={true}
            // handleViewClick={handleViewClick}
            // handlePreviewPod={handlePreviewPod}
            // handleDownloadPod={handleDownloadPod}
          />
            
            {/* <Popup
                open={podImageOpen}
                modal
                contentStyle={{ maxWidth: '700px', width: '90%' }}
                closeOnDocumentClick={false}
                onClose={() => setPodImageOpen(false)}
            >
                <div className="pop-up-container">
                    <div className="pop-up-header">POD Image Preview</div>
                    <div className="pod-img-container">
                        {loadingImage ? (
                            <div className="loader-container">
                            <div className="spinner"></div>
                            <p>Loading...</p>
                            </div>
                        ) : imageUrl ? (
                            <img
                                className="pod-img"
                                src={imageUrl}
                                alt="POD Image Preview"
                                style={{ transform: `rotate(${rotationAngle}deg)` }}
                            />
                        ) : (
                            <div className="error-message">
                                <p>Image not available or corrupted.</p>
                            </div>
                        )}
                    </div>
                    <div className="pop-up-actions">
                        <button
                            className="pop-up-button"
                            onClick={() => setPodImageOpen(false)}
                        >
                            Close
                        </button>
                        <button 
                            onClick={handleRotate} 
                            className="pop-up-button"
                        >
                            Rotate
                        </button>
                    </div>
                </div>
            </Popup> */}

          <div className="form-footer">
            <button style={{ display: "none" }}>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="pod_statement_report_excel"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Download as XLS"
                ref={(a) => (download_ref.current = a)}
              />
            </button>
            <button
              className="download-table-xls-button"
              onClick={async () => {
                setLoading(true);
                let dataToSend = {
                  date_dict: finalInput.date_dict,
                  filter_fields: finalInput.filter_fields,
                };

                console.log("data to send in EXCEL:", dataToSend);

                let resp = await fetch(SERVER_URL + "/report/pod_statement", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(dataToSend),
                });
                let response = await resp.json();

                if (response["data"] && "total_rows" in response) {
                  console.log(response["data"], "fsdfafsfds");
                  // setData(response["data"]);
                  ex_data = response["data"];
                  // setPageCount(Math.ceil(response["total_rows"] / pageSize));
                }
                setLoading(false);
                download_ref.current.handleDownload();
              }}
            >
              Download as Excel
            </button>
            {ReportExcel.pod_statement_excel(
              { columns },
              { ex_data },
              { fetchData },
              { dateState },
              { loading },
              { pageCount },
              { dateState }
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PodReciveReport;