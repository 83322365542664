import React, { useEffect } from "react";
import { SERVER_URL } from "../config/config.js";
import "./biltyReport.css";
import ReportTable from "./ReportTable";
import Popup from "reactjs-popup";
import ReportExcel from "./ReportExcel.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DynamicViewTable from "./DynamicViewTable";
import { apiFetch } from "../utils/apiUtils.js";

function EwbExtensionReport({ sessionObject }) {
  const [checkedList, setCheckedList] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Ewb No",
        accessor: "eway_bill_no",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Ewb Date",
        accessor: "date",
        width: "70px",
        minWidth: "50px",
        canFilter: true,
      },
      {
        Header: "E. Time",
        accessor: "extended_times",
        width: "30px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: " Challan No",
        accessor: "booking_chalan_no",
        width: "65px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Challan Date",
        accessor: "chalan_date",
        width: "70px",
        minWidth: "50px",
        canFilter: true,
      },
      {
        Header: " Challan From",
        accessor: "chalan_station_from",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: " Challan To",
        accessor: "chalan_station_to",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: " Bilty No",
        accessor: "bilty_no",
        width: "65px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Bilty Date",
        accessor: "bilty_date",
        width: "75px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "From Station",
        accessor: "from_station",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "To Station",
        accessor: "to_station",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Consignor Party",
        accessor: "consignor_name",
        width: "150px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Consignee Party",
        accessor: "consignee_name",
        width: "150px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Truck No",
        accessor: "vehicle_no",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Error",
        accessor: "error",
        width: "70px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Ewb Amt",
        accessor: "amount",
        width: "58px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Manually Stopped",
        accessor: "manually_stopped",
        width: "58px",
        minWidth: "10px",
        canFilter: true,
      },
      // {
      //   Header: "Cewb No",
      //   accessor: "cewb_no",
      //   canFilter: true,
      // },
    ],
    []
  );

  const printColumns = React.useMemo(
    () => [
      {
        Header: "Ewb No",
        parent: "detail",
        accessor: "ewbNo",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Vehicle No",
        parent: "detail",
        accessor: "vehicleNo",
        width: "10px",
        minWidth: "10px",
        maxWidth: "40px",
        canFilter: true,
      },
      {
        Header: "Consignor",
        parent: "detail",
        accessor: "fromTrdName",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Consignee",
        parent: "detail",
        accessor: "fromTrdName",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "From Place",
        parent: "detail",
        accessor: "fromPlace",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      // {
      //   Header: "Form Pincode",
      //   parent: "detail",
      //   accessor: "fromPincode",
      //   width: "90px",
      //   minWidth: "10px",
      //   canFilter: true,
      // },
      {
        Header: "To Place",
        parent: "detail",
        accessor: "toPlace",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
      // {
      //   Header: "To Pincode",
      //   parent: "detail",
      //   accessor: "toPincode",
      //   width: "90px",
      //   minWidth: "10px",
      //   canFilter: true,
      // },

      {
        Header: "Ewb Date",
        parent: "detail",
        accessor: "ewbDate",
        width: "70px",
        minWidth: "50px",
        canFilter: true,
      },
      {
        Header: "Valid Upto",
        parent: "detail",
        accessor: "validUpto",
        width: "90px",
        minWidth: "10px",
        canFilter: true,
      },
    ],
    []
  );

  const consignorBillingTableHeader = [
    { label: "Sr. No.", className: "text-center table-header" },
    { label: "Ewv. No.", className: "text-center table-header" },
    { label: "E. Times", className: "text-center table-header" },
    { label: "Vehicle No.", className: "text-center table-header" },
    { label: "Consignor", className: "text-center table-header" },
    { label: "Consignee", className: "text-center table-header" },
    { label: "From", className: "text-center table-header" },
    { label: "To", className: "text-center table-header" },
    { label: "Ewb Date", className: "text-center table-header" },
    { label: "Valid upto", className: "text-center table-header" },
  ];

  const consignorBillingTableItems = [
    {
      type: "text",
      name: "ewbNo",
      label: "Ewb No",
      className: "form-control-medlarge-col",
    },
    {
      type: "text",
      name: "extended_times",
      label: "Vehicle No",
      className: "form-control-medlargemed-col",
    },
    {
      type: "text",
      name: "truck_number",
      label: "Vehicle No",
      className: "form-control-medlarge-col",
      //   convertFunction: (itemObject)=>{
      //     return formatDate(itemObject.created_date)
      //   }
    },
    {
      type: "text",
      name: "consignor_name",
      label: "Consignor",
      className: "form-control-medlarge-col",
    },
    {
      type: "text",
      name: "consignee_name",
      label: "Consignee",
      className: "form-control-medlarge-col",
    },
    {
      type: "text",
      name: "fromPlace",
      label: "From Place",
      className: "form-control-medlarge-col",
    },
    {
      type: "text",
      name: "consignee_place",
      label: "To Place",
      className: "form-control-medlarge-col",
    },
    {
      type: "text",
      name: "ewb_date",
      label: "Ewb Date",
      className: "form-control-medlarge-col",
    },
    {
      type: "text",
      name: "validUpto",
      label: "Valid Date",
      className: "form-control-medlarge-col",
    },
  ];

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [isStop, setIsStop] = React.useState(false);
  const [isStopRR, setIsStopRR] = React.useState(false);
  const [isExtend, setIsExtend] = React.useState(false); // EXTENDED
  const [popupPassword, setPopupPassword] = React.useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [responseFromServer, setResponseFromServer] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);
  const [isPrint, setIsPrint] = React.useState(false); // Print
  const [printData, setPrintData] = React.useState([]); // Print data
  const [popupLoading, setPopupLoading] = React.useState(true);
  const [isTableChecked, setIsTableChecked] = React.useState(false);
  const fetchIdRef = React.useRef(0);
  const sortIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, customFilters }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      console.log("12323", sortBy, customFilters, fetchId);
      // Set the loading state
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        // customFilters.created_from = String(
        //   sessionObject.sessionVariables.branch_id
        // );

        if ("in_transit" in customFilters) {
          let response = await fetch(
            SERVER_URL + "/report/eway_bill_extension",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                paginate: {
                  number_of_rows: pageSize,
                  page_number: pageIndex + 1,
                },
                sort_fields: sortBy,
                filter_fields: customFilters,
              }),
            }
          );
          let resp = await response.json();
          if (resp["data"] && "total_rows" in resp) {
            setData(resp["data"]);
            setPageCount(Math.ceil(resp["total_rows"] / pageSize));
          }
          setLoading(false);
        }
        if ("greater_than_extended_times" in customFilters) {
          let response = await fetch(
            SERVER_URL + "/report/eway_bill_extension",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                paginate: {
                  number_of_rows: pageSize,
                  page_number: pageIndex + 1,
                },
                sort_fields: sortBy,
                filter_fields: customFilters,
              }),
            }
          );
          let resp = await response.json();
          if (resp["data"] && "total_rows" in resp) {
            setData(resp["data"]);
            setPageCount(Math.ceil(resp["total_rows"] / pageSize));
          }
          setLoading(false);
        }
      }
      // We'll even set a delay to simulate a server here
      //   setTimeout(() => {
      //     // Only update the data if this is the latest fetch
      //     if (fetchId === fetchIdRef.current) {
      //       const startRow = pageSize * pageIndex;
      //       const endRow = startRow + pageSize;
      //     //   setData(serverData.slice(startRow, endRow));
      //       console.log("data", data);
      //       // Your server could send back total page count.
      //       // For now we'll just fake it, too
      //       setPageCount(Math.ceil(serverData.length / pageSize));

      //       setLoading(false);
      //     }
      //   }, 1000);
    },
    []
  );

  const handleCheckboxChange = (row) => {
    const val = row[0].value;
    const ind = checkedList.indexOf(val);
    let tempChecked = [...checkedList];
    if (ind > -1) {
      tempChecked.splice(ind, 1);
    } else {
      tempChecked.push(val);
    }
    setCheckedList(tempChecked);
  };

  const handleCheckboxChangeRR = (arr, item, idx) => {
    console.log("item", item, idx);
    console.log("arrr", arr.target, arr.target.checked);
    let dummyArray = printData;

    if (arr.target.checked) {
      dummyArray[idx].checked = "1";
    } else {
      dummyArray[idx].checked = "0";
    }
    console.log("bilty ids dummy", dummyArray);
    setPrintData([...dummyArray]);
  };

  const selectAll = () => {
    let dummyArray = myForm.pageState.bilty_ids;
    for (let i = 0; i < dummyArray.length; i++) {
      dummyArray[i].checked = "1";
    }
    let newState = {
      bilty_ids: dummyArray,
    };
    let total_amount = 0;
    let tran_freight = 0;
    let our_freight = 0;
    let to_pay = 0;
    let paid = 0;
    let pkgs = 0;
    let bilty_charge = 0;
    let extra_hamali = 0;
    let dd_charge = 0;
    let biltyObj = {};
    for (let i = 0; i < newState.bilty_ids.length; i++) {
      if (newState.bilty_ids[i].checked == "0") {
        continue;
      }
      biltyObj = newState.bilty_ids[i];
      tran_freight += parseFloat(biltyObj.transporter_freight) || 0;
      our_freight += parseFloat(biltyObj.item_amount) || 0;
      bilty_charge += parseFloat(biltyObj.bilty_charge) || 0;
      dd_charge += parseFloat(biltyObj.door_del_charge) || 0;
      extra_hamali += parseFloat(biltyObj.hamali) || 0;
      if (biltyObj.pay_type == "1") {
        to_pay += parseFloat(biltyObj.transporter_freight) || 0;
      } else {
        paid += parseFloat(biltyObj.transporter_freight) || 0;
      }
      pkgs += parseFloat(biltyObj.no_of_package) || 0;
    }
    //   newState.transporter_freight = String(tran_freight);
    total_amount = our_freight + bilty_charge + dd_charge + extra_hamali;
    newState.gross_amount = String(parseInt(our_freight));
    newState.bilty_charge = String(bilty_charge);
    newState.door_del_charge = String(dd_charge);
    newState.extra_hamali = String(extra_hamali);
    newState.total_amount = String(parseInt(total_amount));
    // myForm.setPageStateByField("bilty_ids", dummyArray)
    myForm.setPageState({
      ...myForm.pageState,
      ...newState,
    });
  };

  const deselectAll = () => {
    let dummyArray = myForm.pageState.bilty_ids;
    for (let i = 0; i < dummyArray.length; i++) {
      dummyArray[i].checked = "0";
    }
    let newState = {
      bilty_ids: dummyArray,
    };
    let total_amount = 0;
    let tran_freight = 0;
    let our_freight = 0;
    let to_pay = 0;
    let paid = 0;
    let pkgs = 0;
    let bilty_charge = 0;
    let extra_hamali = 0;
    let dd_charge = 0;
    let biltyObj = {};
    for (let i = 0; i < newState.bilty_ids.length; i++) {
      if (newState.bilty_ids[i].checked == "0") {
        continue;
      }
      biltyObj = newState.bilty_ids[i];
      tran_freight += parseFloat(biltyObj.transporter_freight) || 0;
      our_freight += parseFloat(biltyObj.item_amount) || 0;
      bilty_charge += parseFloat(biltyObj.bilty_charge) || 0;
      dd_charge += parseFloat(biltyObj.door_del_charge) || 0;
      extra_hamali += parseFloat(biltyObj.hamali) || 0;
      if (biltyObj.pay_type == "1") {
        to_pay += parseFloat(biltyObj.transporter_freight) || 0;
      } else {
        paid += parseFloat(biltyObj.transporter_freight) || 0;
      }
      pkgs += parseFloat(biltyObj.no_of_package) || 0;
    }
    //   newState.transporter_freight = String(tran_freight);
    total_amount = our_freight + bilty_charge + dd_charge + extra_hamali;
    newState.gross_amount = String(parseInt(our_freight));
    newState.bilty_charge = String(bilty_charge);
    newState.door_del_charge = String(dd_charge);
    newState.extra_hamali = String(extra_hamali);
    newState.total_amount = String(parseInt(total_amount));
    // myForm.setPageStateByField("bilty_ids", dummyArray)
    myForm.setPageState({
      ...myForm.pageState,
      ...newState,
    });
  };

  const deselectAllRR = () => {
    let dummyArray = printData;
    for (let i = 0; i < dummyArray.length; i++) {
      dummyArray[i].checked = "0";
    }
    setPrintData([...dummyArray]);
  };

  const selectAllRR = () => {
    let dummyArray = printData;
    for (let i = 0; i < dummyArray.length; i++) {
      dummyArray[i].checked = "1";
    }
    setPrintData([...dummyArray]);
  };

  const getSelectAllValueRR = () => {
    for (let i = 0; i < printData.length; i++) {
      if (printData[i].checked != "1") {
        return false;
      }
    }
    return true;
  };

  const handleSelectAll = () => {
    let tempChecked = [];
    data.forEach((row) => {
      tempChecked.push(row.eway_bill_no);
    });
    setCheckedList(tempChecked);
  };

  const handleDeselectAll = () => {
    setCheckedList([]);
  };

  const getSelectAllValue = () => {
    const isAllSelected = checkedList.length == data.length;
    return isAllSelected;
  };

  const isChecked = (row) => {
    const val = row[0].value;
    return checkedList.indexOf(val) > -1;
  };

  const handleStopClick = async () => {
    const url = SERVER_URL + "/ewb/remove_godown_entry";

    const dataToSend = checkedList;

    const resp = await apiFetch(
      url,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      },
      sessionObject
    );

    if (!resp.ok) {
      setResponseFromServer({
        flag: 0,
        details: "Something went wrong",
      });
      return;
    }

    const respDetails = await resp.json();
    //   console.log(respDetails);
    setSubmitted(true);
    setResponseFromServer(respDetails);
  };

  // *******CODE FOR EXTEND BUTTON********
  const handleExtend = async () => {
    console.log("EXTEND");
    const url = SERVER_URL + "/ewb/in_transit_extend";

    const dataToSend = checkedList;

    const resp = await apiFetch(
      url,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      },
      sessionObject
    );
    console.log("RESP", resp);
    if (resp.ok) {
      setResponseFromServer({
        flag: 1,
        details: "1",
      });
      return;
    } else if (!resp.ok) {
      setResponseFromServer({
        flag: 0,
        details: "Something went wrong",
      });
      return;
    }

    const respDetails = await resp.json();
    //   console.log(respDetails);
    setSubmitted(true);
    setResponseFromServer(respDetails);
  };
  // ******END************

  const handleAuto = async () => {
    setLoading(true);

    // Assuming status should be set to true in the URL query parameter
    const url = `https://backend.myrcc.in:8001/ewb/update_eway_bill_extension_status/?status=true`;

    // Ensure `checkedList` is an array of strings
    const ewbNumbersArray = Array.isArray(checkedList)
      ? checkedList
      : [checkedList];

    try {
      const resp = await apiFetch(
        url,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(ewbNumbersArray), // Send the array of EWB numbers as JSON
        },
        sessionObject
      );

      if (resp.ok) {
        const respDetails = await resp.json();
        setResponseFromServer({
          flag: 1,
          details: respDetails.details || "Status updated successfully",
        });
      } else {
        const errorResponse = await resp.json();
        setResponseFromServer({
          flag: 0,
          details: errorResponse.details || "Something went wrong",
        });
      }
    } catch (error) {
      setResponseFromServer({
        flag: 0,
        details: "An error occurred. Please try again.",
      });
    } finally {
      setLoading(false);
      setSubmitted(true);
    }
  };

  const handleTableChecked = async (event) => {
    const { checked } = event.target;
    setIsTableChecked(checked);

    const url = SERVER_URL + "/report/update_rr_today_expiration_ewb";
    try {
      const response = await apiFetch(
        url,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(printData),
        },
        sessionObject
      );

      if (!response.ok) {
        throw new Error("Error fetching print data");
      }

      const data = await response.json();
      setPrintData(data.data);
      setPopupLoading(false);
    } catch (error) {
      console.error("Error fetching print data:", error);
      // Handle the error state here
    }
  };

  // *******CODE FOR Print BUTTON********
  const handlePrint = async () => {
    const url = SERVER_URL + "/report/get_rr_today_expiration_ewb";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error fetching print data");
      }

      const data = await response.json();
      let dataNew = [];
      for (let i = 0; i < data.data.length; i++) {
        dataNew.push(data.data[i].detail);
      }
      setPrintData(dataNew);
      setPopupLoading(false);
    } catch (error) {
      console.error("Error fetching print data:", error);
      // Handle the error state here
    }
  };
  // ******END************

  const handleEwaybillStop = async () => {
    const url = SERVER_URL + "/ewb/eway_bill_stop";

    const dataToSend = checkedList;

    const resp = await apiFetch(
      url,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      },
      sessionObject
    );

    if (!resp.ok) {
      setResponseFromServer({
        flag: 0,
        details: "Something went wrong",
      });
      return;
    }

    const respDetails = await resp.json();
    setSubmitted(true);
    setResponseFromServer(respDetails);
  };

  const handleEwaybillStopRR = async () => {
    const url = SERVER_URL + "/report/update_rr_today_expiration_ewb";

    let stoppedObj = [];

    for (let i = 0; i < printData.length; i++) {
      if (printData[i].checked == "1") {
        stoppedObj.push(printData[i]);
      }
    }

    const resp = await apiFetch(
      url,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: stoppedObj }),
      },
      sessionObject
    );

    if (!resp.ok) {
      setResponseFromServer({
        flag: 0,
        details: "Something went wrong",
      });
      return false;
    }

    const respDetails = await resp.json();
    setSubmitted(true);
    setResponseFromServer(respDetails);
  };

  return (
    <div className="report-bilty">
      <div className="form-header">
        <div className="ewb-header">Ewb Extension Report</div>
        <button
          onClick={() => {
            handlePrint(), setIsPrint(true);
          }}
        >
          LR Pending
        </button>
      </div>

      {/* POPUP FOR Print VALUES */}
      <div>
        <Popup open={isPrint} modal closeOnDocumentClick={false}>
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">
                {popupLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <DynamicViewTable
                      checkBox={1}
                      tableHeader={consignorBillingTableHeader}
                      tableItems={consignorBillingTableItems}
                      tableValues={printData}
                      // setPageStateByField={myForm.setPageStateByField}
                      pageStateArray={printData}
                      // deleteEntryFromTableCallback={deleteEntryFromTableCallback}
                      handleCheckboxChange={handleCheckboxChangeRR}
                      fieldMapping="bilty_ids"
                      getSelectAllValue={getSelectAllValueRR}
                      selectAll={selectAllRR}
                      deselectAll={deselectAllRR}
                    />
                    {/* <table border={1} className="rr-table">
                          <thead>
                          <tr>
                            {printColumns.map((column) => (
                              <th align="left">
                                  {column.Header}
                              </th>
                            ))}
                          </tr>
                          </thead>
                          <tbody>
                          {printData.map(row => {
                            return (
                                <tr>
                                    {printColumns.map(col => {
                                        return (
                                            <td align="left" style={{ width: col.width, minWidth: col.minWidth }}>{row[col.parent][col.accessor]}</td>
                                        )
                                    })}
                                </tr>
                            )
                          })}
                          </tbody>
                        </table> */}
                  </>
                )}
              </div>

              <div className="rr-pop-up-actions">
                <button
                  className="pop-up-button"
                  id="yes_button"
                  onClick={() => {
                    setIsPrint(false);
                    close();
                  }}
                >
                  Close
                </button>
                <button
                  className="pop-up-button"
                  id="yes_button"
                  onClick={() => {
                    setIsPrint(false);
                    setIsStopRR(true);
                    close();
                  }}
                >
                  Stop
                </button>
                <div>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="account_report_excel"
                    filename="ewb_without_bilty"
                    sheet="tablexls"
                    buttonText="Download"
                  />
                  {ReportExcel.ewb_without_bilty_excel(printData)}
                </div>
              </div>
            </div>
          )}
        </Popup>
      </div>

      {/* END OF POPUP FOR Print VALUES */}

      {/* POPUP FOR EXTENDED VALUES */}
      <div>
        <Popup open={isExtend} modal closeOnDocumentClick={false}>
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">
                {checkedList.length} entries will be affected
              </div>

              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  id="yes_button"
                  onClick={() => {
                    setIsExtend(false);
                    close();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>

      {/* END OF POPUP FOR EXTENDED VALUES */}

      <div>
        <Popup open={isStop} modal closeOnDocumentClick={false}>
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">Are you sure want to delete?</div>
              <div className="pop-up-fields">
                <div className="pop-up-field">
                  <div className="pop-up-field-value">
                    ({checkedList.length} entries will be affected){" "}
                  </div>
                </div>
                <br />
                <div className="pop-up-field">
                  <div className="pop-up-field-label">Password: </div>
                  <input
                    type="password"
                    className="form-input"
                    value={popupPassword}
                    onChange={(e) => {
                      setPopupPassword(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key == "Enter") {
                        if (popupPassword.toLowerCase() === "stop5661") {
                          setIsPasswordCorrect(true);
                        }
                      }
                      // if(e.key == "Enter") {
                      //     const url = SERVER_URL + "/login/access-token";
                      //     const dataToSend = {
                      //         username: sessionObject.sessionVariables.user_name,
                      //         password: popupPassword,
                      //     }

                      //     const resp = await fetch(url, {
                      //         method: "POST",
                      //         headers: {
                      //             Accept: "application/json",
                      //             "Content-Type": "application/x-www-form-urlencoded",
                      //         },
                      //         body: new URLSearchParams(dataToSend),
                      //         // body: JSON.stringify(dataToSend),
                      //     })

                      //     if(resp.ok) {
                      //         const loginData = await resp.json();
                      //         if("access_token" in loginData) {
                      //             setIsPasswordCorrect(true);
                      //             document.getElementById("yes_button").focus();
                      //         }
                      //     }
                      // }
                    }}
                  />
                </div>
              </div>
              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  id="yes_button"
                  onClick={() => {
                    // handleStopClick();   # COMMENTED TO CALL EWAY_BILL_STOP INSTEAD OF REMOVE_GODOWN_ENTRY
                    handleEwaybillStop();
                    setIsStop(false);
                    setIsPasswordCorrect(false);
                    setPopupPassword("");
                    close();
                  }}
                  disabled={!isPasswordCorrect}
                >
                  Yes
                </button>
                <button
                  className="pop-up-button"
                  // autoFocus={true}
                  id="no_button"
                  onClick={() => {
                    setIsStop(false);
                    setIsPasswordCorrect(false);
                    setPopupPassword("");
                    close();
                  }}
                >
                  No
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>

      <div>
        <Popup open={isStopRR} modal closeOnDocumentClick={false}>
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">Are you sure want to Stop?</div>
              <div className="pop-up-fields">
                {/* <div className='pop-up-field'>
                        <div className="pop-up-field-value">({checkedList.length} entries will be affected) </div>
                    </div> */}
                <br />
                <div className="pop-up-field">
                  <div className="pop-up-field-label">Password: </div>
                  <input
                    type="password"
                    className="form-input"
                    value={popupPassword}
                    onChange={(e) => {
                      setPopupPassword(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key == "Enter") {
                        if (popupPassword.toLowerCase() === "stop5661") {
                          setIsPasswordCorrect(true);
                        }
                      }
                    }}
                  />
                </div>
              </div>
              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  id="yes_button"
                  onClick={() => {
                    // handleStopClick();   # COMMENTED TO CALL EWAY_BILL_STOP INSTEAD OF REMOVE_GODOWN_ENTRY
                    handleEwaybillStopRR();
                    setIsStop(false);
                    setIsPasswordCorrect(false);
                    setPopupPassword("");
                    close();
                  }}
                  disabled={!isPasswordCorrect}
                >
                  Yes
                </button>
                <button
                  className="pop-up-button"
                  // autoFocus={true}
                  id="no_button"
                  onClick={() => {
                    setIsStop(false);
                    setIsPasswordCorrect(false);
                    setPopupPassword("");
                    close();
                  }}
                >
                  No
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>

      <div>
        <Popup open={submitted} modal closeOnDocumentClick={false}>
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">
                {setResponseFromServer.flag == 0 ? (
                  <div> Success :) </div>
                ) : (
                  <div> Failed ): </div>
                )}
                <div>
                  <a
                    className="pop-up-close btn"
                    onClick={() => {
                      close();
                      window.location.reload();
                    }}
                  >
                    &times;
                  </a>
                </div>
              </div>
              <div className="pop-up-fields">
                {Object.keys(responseFromServer).map((key) => {
                  return key == "flag" ? (
                    <div />
                  ) : (
                    <div className="pop-up-field">
                      <div className="pop-up-field-label">{key} : </div>
                      <div className="pop-up-field-value">
                        {responseFromServer[key]}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  onClick={() => {
                    // setSubmitted(false);
                    // setResponseFromServer({});
                    close();
                    window.location.reload();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>

      <div className="report-bilty-table-container">
        <ReportTable
          checkbox={true}
          handleCheckboxChange={handleCheckboxChange}
          checkedList={checkedList}
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          isChecked={isChecked}
          getSelectAllValue={getSelectAllValue}
          selectAll={handleSelectAll}
          deselectAll={deselectAll}
          handleDeselectAll={handleDeselectAll}
          title="EwbExtensionReport"
        />
        <div className="form-footer">
          <button onClick={() => setIsStop(true)}>Stop</button>
          <button
            onClick={() => {
              handleExtend(), setIsExtend(true);
            }}
          >
            Extend
          </button>
          <button
            onClick={() => {
              handleAuto(), setIsAuto(true);
            }}
          >
            Auto
          </button>
        </div>
      </div>
    </div>
  );
}

export default EwbExtensionReport;
