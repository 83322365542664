
import React from "react";
import PodReciveReport from "../PodReciveReport";

export default function ReportPendingPodImage({ sessionObject }) {
  if (!sessionObject.sessionVariables["modules"].includes("pod-recive-report")) {
    return;
  }
  return (
    <div className="page-bilty">
      <PodReciveReport sessionObject={sessionObject} />
    </div>
  );
}