import React, { useEffect, useState } from "react";
import DynamicTable from "./DynamicTable";
import DynamicViewTable from "./DynamicViewTable";
import Popup from "reactjs-popup";
import "./Form.css";
import { biltyInquiryApiConfig } from "../config/apiConfig.js";
import { PRINT_SERVER_URL, SERVER_URL, UTILITY_SERVER_URL } from "../config/config";
import FormColumn from "./FormColumn.js";
import "./FieldView.css";
import {
  groupInfo,
  validate,
  dataObject,
  popupInfo,tripChallanTableHeader,tripChallanTableItems
} from "../config/BiltyInquiry";
import {
  itemTableHeader,
  itemTableItems,
  ewbTableItems,
  ewbTableHeader,
} from "../config/tableItems";
import Autosuggest from "react-autosuggest";
import "./AutoSuggest.css";
import useForm from "./useForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./popup.css";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../components/ComponentToPrint";
import print from "print-js";

const BiltyInquiryForm = ({ sessionObject }) => {

  const [imageUrl, setImageUrl] = useState(null);
  const [podImageOpen, setPodImageOpen] = useState(false);
  const [isPodButtonDisabled, setIsButtonDisabled] = useState(true);
  const [podOpen, setPodOpen] = useState(false);
  const showFlagPod = () => {
    setPodOpen(!podOpen);
  };
  const [rotationAngle, setRotationAngle] = useState(270);

  const contentStyle = {
    maxWidth: "600px",
    width: "90%",
  };
  let variablesFromSession = {
    station_from: String(sessionObject.sessionVariables.branch_id),
    station_from_name: sessionObject.sessionVariables.branch_name,
    created_from: String(sessionObject.sessionVariables.branch_id),
    created_by: String(sessionObject.sessionVariables.user_id),
    company_id: sessionObject.sessionVariables.company_id ?? "1",
    fYear: sessionObject.sessionVariables.financial_year,
    // "last_bilty_no": String(sessionObject.sessionVariables.last_bilty_no),
  };
  const myForm = useForm(
    "Bilty Inquiry",
    validate,
    { ...dataObject, ...variablesFromSession },
    biltyInquiryApiConfig
  );

  const getAdditionalInfoForSuggestionFetch = (fieldInfoObject) => {
    let additionalInfoObject = {};
    if (fieldInfoObject.name == "consignor_name"){
      additionalInfoObject.branch_id = myForm.pageState.created_from;
      // additionalInfoObject.pay_type = myForm.pageState.pay_type;
      return additionalInfoObject;
    }
    return null;
  };

  useEffect(() => {
    const callApi = async () => {
      if (myForm.pageState.bilty_id && myForm.pageState.is_pod_image == 1 ) {
        const apiUrl = `${SERVER_URL}/pod_image/?bilty_id=${myForm.pageState.bilty_id}`;
        const response = await fetch(apiUrl);
        if (response.status === 200) {
          const blob = await response.blob();
          const imageObjectUrl = URL.createObjectURL(blob);
          setImageUrl(imageObjectUrl);
          setIsButtonDisabled(false);
        } else {
          console.error("API call failed with status:", response.status);
          setIsButtonDisabled(true);
        }
      } else {
        console.log("Conditions not met. Skipping API call.");
        setIsButtonDisabled(true);
      }
    };

    callApi();
  }, [myForm.pageState.bilty_id, myForm.pageState.is_pod_image]);

  const handleImageUpload = async () => {
    setPodOpen(false);
    let file = await new Promise((resolve) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (event) => resolve(event.target.files[0]);
      input.click();
    });
    myForm.setOverlay(true)
    const apiUrl = `${SERVER_URL}/pod_image?image_id=${myForm.pageState.bilty_id}&type=${"bilty_pod"}&client_name=${"rcc"}`;
    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(apiUrl, { method: "POST", body: formData });
    myForm.setOverlay(false)
    let responseData = await response.json()
    // console.log("RESSSSSSSSSSSSS",responseData)
    //Adding Alert Based on Status Code in Upload POD
    if(responseData.status_code == 407){
      alert("QR code not visible")
    }else if(responseData.status_code == 406){
      alert("QR Code is mismatch")
    }else{
      alert("Image uploaded successfully");
    }
  }   

  function formatDateToDDMMYYYY(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    const podDate = myForm.pageState?.pod_upload_date;
    
    if (podDate) {
      const formattedPodDate = formatDateToDDMMYYYY(podDate)
      myForm.setPageState(oldState => ({
          ...oldState,
          pod_uploaded_date: formattedPodDate,
      }));
    }
  },[myForm.pageState.media_info]); 

  const handleDownloadPod = async () => {
    try {
      const apiUrl = `${SERVER_URL}/pod_image/?bilty_id=${myForm.pageState.bilty_id}`;
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      const blobUrl = URL.createObjectURL(blob);
      downloadLink.href = blobUrl;
      downloadLink.download = 'pod_image.jpg';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };


  const checkVisibilityCondition = (fieldInfo) => {
    if (
      myForm.pageState.pay_type == "4" &&
      (fieldInfo.name == "freight" || fieldInfo.name == "total_amount")
    ) {
      return false;
    } else if (
      myForm.pageState.pay_type == "2" &&
      fieldInfo.name == "delete_button"
    ) {
      return false;
    } else if (
      myForm.pageState.mr_no != "" &&
      fieldInfo.name == "delete_button"
    ) {
      return false;
    } else if (
      myForm.pageState.pay_type == "2" &&
      fieldInfo.name == "edit_button"
    ) {
      return false;
    } else if (
      myForm.pageState.mr_no != "" &&
      fieldInfo.name == "edit_button"
    ) {
      return false;
    } else if (
      myForm.pageState.is_crossing == "N" &&
      fieldInfo.name == "transporter_name"
    ) {
      return false;
    } else if (
      myForm.pageState.is_crossing == "n" &&
      fieldInfo.name == "transporter_name"
    ) {
      return false;
    } else if (
      myForm.pageState.is_crossing == "" &&
      fieldInfo.name == "transporter_name"
    ) {
      return false;
    } else if (
      myForm.pageState.is_crossing == "N" &&
      fieldInfo.name == "transporter_freight"
    ) {
      return false;
    } else if (
      myForm.pageState.is_crossing == "n" &&
      fieldInfo.name == "transporter_freight"
    ) {
      return false;
    } else if (
      myForm.pageState.is_crossing == "" &&
      fieldInfo.name == "transporter_freight"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkDisabledCondition = (fieldInfo) => {
    if (myForm.pageMode == "view" && fieldInfo.name != "bilty_no") {
      return "disabled";
    } else if (myForm.pageMode == "edit" && fieldInfo.name == "input_date") {
      return "disabled";
    }else if (fieldInfo.name != "bilty_no" && fieldInfo.name != "is_crossing" && fieldInfo.name != "transporter_name") {
      return "disabled";}
    else {
      return "";
    }
  };

  const handleDelete = async () => {
    const url = SERVER_URL + "/bilty/?bilty_id=" + myForm.pageState.bilty_id;
    const response = await fetch(url, { method: "DELETE" });
    if (!response.ok) {
      console.log("Not able to delete bilty");
      return;
    }
    const temp_response = await response.json();
    if (temp_response.is_deleted) {
      myForm.setPageState({ ...dataObject, ...variablesFromSession });
      myForm.setPageMode("write");
      window.location.reload();
      return;
    }
  };

  const transporterFieldInfo = {
    label: "Transporter",
    className: "form-row",
    labelClassName: "form-label",
    inputClassName: "form-input",
    name: "transporter_name",
    type: "text",
    placeHolder: "",
    apiConfigKey: "getTransporterSuggestions",
    url: SERVER_URL + "/transporter/",
    suggestionKeyword: "transporter_name",
    suggestionKeywordForFetchApiArgs: "name",
    suggestionChooseQueryKeyword: "transporter_id",
    suggestionSchema: {
      transporter_name: "transporter_name",
      suffix: "suffix",
      transporter_id: "transporter_id",
    },
    apiCallRequiredOnGetValue: true,
    toValidate: true,
    regExpValidation: "[a-zA-z]",
    keyboardNavigationMap: {
      Enter: "bilty_no",
    },
    valueVerificationFromSuggestionNeeded: true,
    valueVerificationCompulsionField: "transporter_id",
  };

  const removeCircularReferences = (obj) => {
    const seen = new Set();
    return JSON.parse(
      JSON.stringify(obj, (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return; 
          }
          seen.add(value);
        }
        return value;
      })
    );
  };
  
  const preparePrintPayload = () => {
    const extraDataObject = {};
  
    let pageStatePrint = myForm.pageState;

    pageStatePrint = removeCircularReferences(pageStatePrint);
    console.log('sss', pageStatePrint)
  
    if (pageStatePrint.pay_type === 1) {
      extraDataObject.to_pay_type = pageStatePrint.freight;
    } else if (pageStatePrint.pay_type === 2 || pageStatePrint.pay_type === 3) {
      extraDataObject.other_type = pageStatePrint.freight;
    }
  
    if (pageStatePrint.eway_bill_no && Array.isArray(pageStatePrint.eway_bill_no)) {
      if (pageStatePrint.eway_bill_no[0].eway_bill_no.trim() === "") {
        extraDataObject.ewb_count = 0;
      } else {
        extraDataObject.ewb_count = pageStatePrint.eway_bill_no.length;
      }
    }
  
    extraDataObject.pod_qr_link = `${pageStatePrint.bilty_id}`;
  
    extraDataObject.client_name = "rcc";
    extraDataObject.file_name = "bilty";
    extraDataObject.created_by = pageStatePrint.created_by_name;
    extraDataObject.bilty_no = pageStatePrint.bilty_no;
    extraDataObject.bilty_date = formatDateToDDMMYYYY(pageStatePrint.input_date);
  
    const tempPayTypeObject = {
      1: "TO PAY",
      2: "PAID",
      3: "FOC",
      4: "TBB",
    };
  
    const tempPackingObject = {
      1: "Carton",
      2: "Cargo",
      3: "Bag",
      4: "Bundle",
      5: "Bucket",
      6: "Box",
      7: "Roll",
      8: "Drum",
      9: "Loose",
      10: "Kantan Pack",
      11: "Plastic Pack",
      12: "Pipe",
      13: "Peti",
      14: "Carboy",
      15: "Barrel",
      16: "Tin",
    };
  
    const tempDeliveryDestTypeObject = {
      1: "Door",
      2: "Godown",
      3: "Priority",
    };
  
    const remarksText = pageStatePrint.remarks ? String(pageStatePrint.remarks).trim() : "";
    if (remarksText === "") {
      console.log("Remarks are empty or null.");
      extraDataObject.remarks = "";
      extraDataObject.remarks_1 = "";
    } else {
      const firstSpaceIndex = remarksText.indexOf(" ");
      if (firstSpaceIndex !== -1) {
        extraDataObject.remarks = remarksText.slice(0, firstSpaceIndex);
        extraDataObject.remarks_1 = remarksText.slice(firstSpaceIndex + 1).trim();
      } else {
        extraDataObject.remarks = remarksText;
        extraDataObject.remarks_1 = "";
      }
    }
  
    extraDataObject.pay_type = tempPayTypeObject[String(pageStatePrint.pay_type)];
  
    for (let i = 0; i < pageStatePrint.item_in.length; i++) {
      const itemNameKey = `item_name_${i}`;
      const amountKey = `amount_${i}`;
      const pkgsKey = `pkgs_${i}`;
      const rateKey = `rate_${i}`;
      const packingTypeKey = `packing_type_${i}`;
      extraDataObject[itemNameKey] = pageStatePrint.item_in[i].item_name;
      extraDataObject[amountKey] = pageStatePrint.item_in[i].amount;
      extraDataObject[pkgsKey] = pageStatePrint.item_in[i].pkgs;
      extraDataObject[rateKey] = pageStatePrint.item_in[i].rate;
      extraDataObject[packingTypeKey] = tempPackingObject[
        String(pageStatePrint.item_in[i].packing_type)
      ];
    }
  
    extraDataObject.delivery_dest_type = tempDeliveryDestTypeObject[
      String(pageStatePrint.delivery_dest_type)
    ];
  
    if (parseInt(pageStatePrint.pay_type) === 4) {
      extraDataObject.bilty_charge = "";
      extraDataObject.hamali = "";
      extraDataObject.door_del_charge = "";
      extraDataObject.other_amount = "";
      extraDataObject.total_amount = "";
      extraDataObject.rate_0 = "";
      extraDataObject.rate_1 = "";
      extraDataObject.rate_2 = "";
      extraDataObject.rate_3 = "";
    }
  
    const dateObject = new Date(pageStatePrint.input_date);
    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();
    const seconds = dateObject.getUTCSeconds();
    const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    extraDataObject.bilty_time = formattedTime;

    Object.keys(pageStatePrint).forEach(key => {
      if (!extraDataObject.hasOwnProperty(key)) {
        extraDataObject[key] = pageStatePrint[key];
      }
    });

  
    return extraDataObject;
  };
  
  const handlePrintBilty = async () => {
    myForm.setOverlay(true);
    const extraDataObject = preparePrintPayload();
  
    try {
      const printResponse = await fetch(
        `${UTILITY_SERVER_URL}/bilty/print/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(extraDataObject), 
        }
      );
  
      if (!printResponse.ok) {
        throw new Error("Failed to print the table");
      }
  
      const blobData = await printResponse.blob();
  
      if (blobData.size === 0) {
        throw new Error("Blob is empty, cannot print");
      }
  
      const blobUrl = URL.createObjectURL(blobData);
      console.log("Blob URL created:", blobUrl);
  
      print({ printable: blobUrl, type: "pdf", showModal: false });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      myForm.setOverlay(false);
    }
  };

  const handleRotate = () => {
    setRotationAngle((prevAngle) => prevAngle + 90);
  };

//   useEffect(() => {
//       console.log(myForm.pageState);
//   })

  return (
    <div className="form-container">
    <div>
        <Popup
            open={myForm.pageState.fyearList.length > 0}
            modal
            closeOnDocumentClick={false}
        >
            {(close) => myForm.displayFyearPopup(close)}  
        </Popup>
    </div>
      <div>
        <Popup
          // trigger={<button className="button"> Open Modal </button>}
          open={myForm.pageMode == "submitted" || myForm.pageMode == "error"}
          modal
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        >
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">
                {" "}
                {myForm.pageMode == "submitted" ? (
                  <div>{popupInfo.success_header}</div>
                ) : (
                  <div>{popupInfo.error_header}</div>
                )}
                <div>
                  <a className="pop-up-close btn" onClick={close}>
                    &times;
                  </a>
                </div>
              </div>
              {myForm.pageMode == "submitted" ? (
                <div className="pop-up-content">
                  {popupInfo.success_title}
                  <br />
                  <div className="pop-up-fields">
                    <div className="pop-up-field">
                      <div className="pop-up-field-label">
                        {popupInfo.field_label_success}
                      </div>
                      <div className="pop-up-field-value">
                        {myForm.pageState[popupInfo.field_name_success]}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pop-up-content">
                  {popupInfo.error_title}
                  <br />
                  <div className="pop-up-fields">
                    <div className="pop-up-field">
                      <div className="pop-up-field-label">
                        {popupInfo.field_label_error}
                      </div>
                      <div className="pop-up-field-value">
                        {myForm.popupError}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  onClick={() => {
                    if (myForm.pageMode == "submitted") {
                      // sessionObject.saveSessionVariableByField("last_bilty_no", {"last_bilty_no":myForm.pageState.bilty_no})
                      myForm.setPageState({
                        ...dataObject,
                        ...variablesFromSession,
                      });
                      myForm.setPageMode("write");
                      window.location.reload();
                      close();
                    } else {
                      myForm.setPageMode("write");
                      close();
                    }
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>

      <div className="form-header">Bilty Inquiry</div>
      <div className="form">
        <div className="form-title">
          {/* <div className="form-row">
            <label className="form-label">Crossing?</label>
            <select
              className="form-input"
              onChange={(newValue) => {
                myForm.handleChangeForSelect(newValue, "is_crossing");
              }}
              ref={(a) => myForm.storeInputReferenceForSelect(a, "is_crossing")}
              disabled={checkDisabledCondition({ name: "is_crossing" })}
              value={myForm.pageState["is_crossing"]}
              onKeyPress={(a) => {
                if (a.key == "Enter") {
                  a.preventDefault();
                  if (
                    myForm.pageState.is_crossing == "N" ||
                    myForm.pageState.is_crossing == "n"
                  ) {
                    myForm.makeFocusOnParticularField("bilty_no");
                    return;
                  } else {
                    myForm.makeFocusOnParticularField("transporter_name");
                    return;
                  }
                }
              }}
            >
              <option value="N" key="N">
                N
              </option>
              <option value="Y" key="Y">
                Y
              </option>
            </select>
          </div> */}
          {/* <div className="form-row">
            {checkVisibilityCondition(transporterFieldInfo) && (
              <label className="form-label">Transprter Name</label>
            )}
            {checkVisibilityCondition(transporterFieldInfo) && (
              <Autosuggest
                id={transporterFieldInfo["name"]}
                suggestions={myForm.suggestions}
                onSuggestionsFetchRequested={(a) =>
                  myForm.onSuggestionsFetchRequested(a, (b) =>
                    myForm.suggestionFetchApi(transporterFieldInfo, b)
                  )
                }
                onSuggestionsClearRequested={() =>
                  myForm.onSuggestionsClearRequested(transporterFieldInfo)
                }
                getSuggestionValue={suggestion => suggestion[transporterFieldInfo.suggestionKeyword]}
                onSuggestionSelected={(a,b) =>
                  myForm.getSuggestionValue(
                    b.suggestion,
                    transporterFieldInfo,
                    myForm.performSuggestions,
                    myForm.updatePageStateForGetSuggestion
                  )
                }
                renderSuggestion={(a) =>
                  myForm.renderSuggestion(a, transporterFieldInfo)
                }
                highlightFirstSuggestion ={true}
                ref={(a) => myForm.storeInputReference(a, false)}
                inputProps={{
                  //placeholder: info["name"],
                  value: String(myForm.pageState[transporterFieldInfo["name"]]),
                  onChange: (a, b) => {
                    myForm.onChangeAutoSuggest(a, b, transporterFieldInfo);
                  },
                  onBlur: () => myForm.onblurValidator(transporterFieldInfo),
                  onKeyPress: (a) => {
                    if (a.key == "Enter") {
                      myForm.makeFocusOnParticularField("bilty_no");
                    }
                  },
                  disabled: checkDisabledCondition(transporterFieldInfo),
                }}
              />
            )}
          </div> */}
          <div className="form-row">
            <label className="form-label">Bilty No:</label>
            <input
            id="size"
              className="form-input"
              type="text"
              name="bilty_no"
              placeholder=""
              value={myForm.pageState.bilty_no}
              onChange={myForm.handleChange}
              onBlur={() => {}}
              onKeyPress={(a) => {
                if(a.key == "Enter") {
                    myForm.getFyearsOnKeyEnter(a, "Bilty Inquiry", myForm.pageState.bilty_no)
                }
              }}
              autoFocus={true}
            // onKeyPress={(e) => {
            //     if(e.key == "Enter") {
            //         getSuffixesOfBilty();
            //     }
            // }} 
              ref={(a) => myForm.storeInputReferenceForSelect(a, "bilty_no")}
              disabled={checkDisabledCondition({ name: "bilty_no" })}
            />
            <select
            id="size"
                className="form-input "
                name="suffix"
                value={myForm.pageState.suffix}
                onChange={(e) => myForm.handleChangeForSelect(e, "suffix")}
                onKeyPress={(e) => {
                    if(e.key == "Enter") {
                        e.preventDefault();
                        myForm.getPageOnKeyEnter(e, myForm.pageState.bilty_no, myForm.pageState.fyear_get_bilty)
                    }
                }}
                ref={(a) => myForm.storeInputReferenceForSelect(a, "suffix")}
            > 
                {myForm.pageState.suffix_options.map((suff) => {
                    return <option value={suff}> {suff} </option>
                })}
            </select>

            {/* <input
              className="form-input-suffix"
              type="text"
              name="suffix"
              placeholder=""
              value={myForm.pageState.suffix}
              onChange={myForm.handleChange}
              onBlur={() => {}}
              onKeyPress={(a) =>
                myForm.getPageOnKeyEnter(a, myForm.pageState.bilty_no)
              }
              ref={(a) => myForm.storeInputReferenceForSelect(a, "suffix")}
            //   disabled={true}
            /> */}
            {myForm.pageMode == "view" && (
              <>
                <button
                  onClick={() => {
                    myForm.setPageState({
                      ...dataObject,
                      ...variablesFromSession,
                    });
                    window.location.reload();
                    myForm.setPageMode("write");
                  }}
                >
                  Clear
                </button>
              </>
            )}
          </div>

          <div className="bilty-inquiry-date">
            Bilty Date:{" "}
            <DatePicker
              dateFormat="dd-MM-yyy"
              selected={myForm.pageState.input_date}
              onChange={(date) =>
                myForm.setPageStateByField("input_date", date)
              }
              ref={(a) => myForm.storeInputReferenceForSelect(a, "input_date")}
              disabled={checkDisabledCondition({ name: "input_date" })}
              onKeyPress={(a) => {
                if (a.key == "Enter") {
                  myForm.makeFocusOnParticularFieldForItem(
                    "eway_bill_no",
                    0,
                    "eway_bill_no"
                  );
                }
              }}
            />
          </div>

          <div className="biltyInquiry-pod">
            <Popup
              open={podImageOpen}
              modal
              contentStyle={contentStyle}
              closeOnDocumentClick={false}
            >
              <div className="pop-up-container">
                <div className="pop-up-header">
                  POD Image
                </div>
                <div className="pod-img-container">
                  {imageUrl && (
                    <img
                      className="pod-img"
                      src={imageUrl}
                      alt="Preview"
                      style={{ transform: `rotate(${rotationAngle}deg)`,  width: rotationAngle % 180 === 0 ? '100%' : '55%',}}
                    />
                  )}
                </div>
                <div className="pop-up-actions">
                  <button
                    className="pop-up-button"
                    onClick={() => setPodImageOpen(false)}
                  >
                    Close
                  </button>
                  <button 
                    onClick={handleRotate} 
                    className="pop-up-button"
                  >
                    Rotate
                  </button>
                </div>
              </div>
            </Popup>

            <span style={{marginRight: "15px"}}>POD Image:</span>
            <Popup
              open={podOpen}
              modal
              contentStyle={contentStyle}
              closeOnDocumentClick={false}
            >
              <div className="pop-up-container">
                <div className="pop-up-header">
                  Upload POD
                </div>
                <button class="clear-button" 
                  disabled={!myForm.pageState.bilty_id}
                  onClick={() => {history.push(`/add-photo?bilty_id=${myForm.pageState.bilty_id}`)}}
                >
                  Open Camera
                </button>
                <button onClick={handleImageUpload}>
                  Choose File
                </button>
                <div className="pop-up-actions">
                  <button
                    className="pop-up-button"
                    onClick={() => {
                      setPodOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Popup>

            <button 
              class="clear-button" 
              disabled={!myForm.pageState.bilty_id}
              onClick={showFlagPod}
            >
              Upload POD
            </button>
            <button 
              class="clear-button" 
              onClick={() => setPodImageOpen(true)}
              disabled={!myForm.pageState.bilty_id || isPodButtonDisabled}
            >
              Preview
            </button>
            <button 
              class="clear-button" 
              disabled={!myForm.pageState.bilty_id || isPodButtonDisabled }
              onClick={handleDownloadPod}
            >
              Download POD
            </button>
          </div>

          <div className="form-row">
            <label style={{fontWeight:"bolder"}}>
              {"Currently In: "+myForm.pageState.owned_by_name}
            </label>
          </div>
          {/* <div>
            Bilty Date:{" "}
            <DatePicker
              dateFormat="dd-MM-yyy"
              selected={myForm.pageState.input_date}
              onChange={(date) =>
                myForm.setPageStateByField("input_date", date)
              }
              ref={(a) => myForm.storeInputReferenceForSelect(a, "input_date")}
              disabled={checkDisabledCondition({ name: "input_date" })}
              onKeyPress={(a) => {
                if (a.key == "Enter") {
                  myForm.makeFocusOnParticularFieldForItem(
                    "eway_bill_no",
                    0,
                    "eway_bill_no"
                  );
                }
              }}
            />
          </div> */}
          {/* <div className="form-row">
            <label className="form-last_bilty">Last Bilty No:</label>
            <label className="form-last_bilty">
              {myForm.pageState.last_bilty_no + myForm.pageState.last_suffix}
            </label>
          </div> */}
        </div>
        <div className="form-input-content-block-0">
          <div className="form-field-left-most responsive-table">
            <DynamicTable
              disabledFlag="true"
              pageStateArray={myForm.pageState["eway_bill_no"]}
              pageMode={myForm.pageMode}
              fieldMapping="eway_bill_no"
              tableHeader={ewbTableHeader}
              tableItems={ewbTableItems}
              setPageStateByField={myForm.setPageStateByField}
              onSuggestionsFetchRequested={myForm.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={myForm.onSuggestionsClearRequested}
              onChangeAutoSuggest={myForm.onChangeAutoSuggest}
              getSuggestionValue={myForm.getSuggestionValue}
              renderSuggestion={myForm.renderSuggestion}
              performSuggestions={myForm.performSuggestions}
              // suggestions={myForm.suggestions}
              suggestions = {(myForm.suggestions != undefined) ? myForm.suggestions : ""}  // changes made for removing error that was caused by undefined suggestion
              suggestionFetchApi={myForm.suggestionFetchApi}
              storeInputReference={myForm.storeInputReference}
              refStoreObject={myForm.refStoreObject}
              makeFocusOnParticularField={myForm.makeFocusOnParticularField}
              makeFocusOnParticularFieldForItem={
                myForm.makeFocusOnParticularFieldForItem
              }
              apiConfig={biltyInquiryApiConfig["getEwbInfo"]}
              pageState={myForm.pageState}
              onblurValidatorForTable={myForm.onblurValidatorForTable}
              storeInputReferenceForSelectForDynamicTable={
                myForm.storeInputReferenceForSelectForDynamicTable
              }
            />
            <div className="border"></div>
            <FormColumn
              groupInfo={groupInfo}
              groupName="group-5"
              checkDisabledCondition={checkDisabledCondition}
              checkVisibilityCondition={checkVisibilityCondition}
              myFormObj={myForm}
              getAdditionalInfoForSuggestionFetch={getAdditionalInfoForSuggestionFetch}
            />
          </div>
          <div className="form-field-left">
            <FormColumn
              groupInfo={groupInfo}
              groupName="group-1"
              checkDisabledCondition={checkDisabledCondition}
              checkVisibilityCondition={checkVisibilityCondition}
              myFormObj={myForm}
              getAdditionalInfoForSuggestionFetch={getAdditionalInfoForSuggestionFetch}
            />
          </div>
          <div className="form-field-right">
            <FormColumn
              groupInfo={groupInfo}
              groupName="group-2"
              checkDisabledCondition={checkDisabledCondition}
              checkVisibilityCondition={checkVisibilityCondition}
              myFormObj={myForm}
              getAdditionalInfoForSuggestionFetch={getAdditionalInfoForSuggestionFetch}
            />
          </div>
        </div>
        <div className="table-container_scroll">
          <DynamicViewTable
            tableHeader={tripChallanTableHeader}
            tableItems={tripChallanTableItems}
            tableValues={myForm.pageState["chalan_info"]}
            setPageStateByField={myForm.setPageStateByField}
            pageStateArray={myForm.pageState["chalan_info"]}
            fieldMapping="chalan_info"
          />
        </div>
        <div className="table-container responsive-table-container">
          <div className="bilty-inquiry-dynamic">
            <DynamicTable
              disabledFlag="true"
              pageStateArray={myForm.pageState["item_in"]}
              pageMode={myForm.pageMode}
              fieldMapping="item_in"
              tableHeader={itemTableHeader}
              tableItems={itemTableItems}
              setPageStateByField={myForm.setPageStateByField}
              onSuggestionsFetchRequested={myForm.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={myForm.onSuggestionsClearRequested}
              onChangeAutoSuggest={myForm.onChangeAutoSuggest}
              getSuggestionValue={myForm.getSuggestionValue}
              renderSuggestion={myForm.renderSuggestion}
              performSuggestions={myForm.performSuggestions}
              // suggestions={myForm.suggestions}
              suggestions = {(myForm.suggestions != undefined) ? myForm.suggestions : ""}  // changes made for removing error that was caused by undefined suggestion
              suggestionFetchApi={myForm.suggestionFetchApi}
              storeInputReference={myForm.storeInputReference}
              refStoreObject={myForm.refStoreObject}
              makeFocusOnParticularField={myForm.makeFocusOnParticularField}
              makeFocusOnParticularFieldForItem={
                myForm.makeFocusOnParticularFieldForItem
              }
              apiConfig={biltyInquiryApiConfig["getEwbInfo"]}
              pageState={myForm.pageState}
              onblurValidatorForTable={myForm.onblurValidatorForTable}
              storeInputReferenceForSelectForDynamicTable={
                myForm.storeInputReferenceForSelectForDynamicTable
              }
            />
            
            {/* <div className="bilty-inquiry-pod-image">
              {imageUrl && <img class="bilty-pod-img" src={imageUrl} alt="Preview" />}
            </div> */}
          </div>
          {/* <div className="table-container">
          <DynamicViewTable
            tableHeader={tripChallanTableHeader}
            tableItems={tripChallanTableItems}
            tableValues={myForm.pageState["temp"]}
            setPageStateByField={myForm.setPageStateByField}
            pageStateArray={myForm.pageState["temp"]}
            fieldMapping="temp"
          />
        </div> */}
          {/* <StaticTable/> */}
          <div className="form-field-vertical_wide">
            {groupInfo["group-3"].map(function (info) {
              if (info["type"] === "dropdown")
                return (
                  <div className={info["className"]} key={info["label"]}>
                    <label className={info["labelClassName"]}>
                      {info["label"]}
                    </label>

                    <select
                      className={info["inputClassName"]}
                      onChange={(newValue) => {
                        myForm.handleChangeForSelect(newValue, info["name"]);
                      }}
                      disabled={checkDisabledCondition(info)}
                      value={myForm.pageState[info["name"]]}
                      ref={(a) =>
                        myForm.storeInputReferenceForSelect(a, info["name"])
                      }
                      onKeyPress={(a) => myForm.onKeyPressForKeyNav(a, info)}
                    >
                      {info["dropdown_items"].map((dropdown_item) => (
                        <option
                          value={dropdown_item.value}
                          key={dropdown_item.label}
                        >
                          {dropdown_item.label}
                        </option>
                      ))}
                    </select>
                    {myForm.internalValidationErrors[info["name"]] && (
                      <p>{myForm.internalValidationErrors[info["name"]]}</p>
                    )}
                  </div>
                );
              else
                return (
                  <div className={info["className"]} key={info["label"]}>
                    {checkVisibilityCondition(info) && (
                      <label className={info["labelClassName"]}>
                        {info["label"]}
                      </label>
                    )}
                    {checkVisibilityCondition(info) && (
                      <Autosuggest
                        id={info["name"]}
                        // suggestions={myForm.suggestions}
                        suggestions = {(myForm.suggestions != undefined) ? myForm.suggestions : ""}  // changes made for removing error that was caused by undefined suggestion
                        onSuggestionsFetchRequested={(a) =>
                          myForm.onSuggestionsFetchRequested(a, (b) =>
                            myForm.suggestionFetchApi(info, b)
                          )
                        }
                        onSuggestionsClearRequested={() =>
                          myForm.onSuggestionsClearRequested(info)
                        }
                        getSuggestionValue={(a) =>
                          myForm.getSuggestionValue(
                            a,
                            info,
                            myForm.performSuggestions,
                            myForm.updatePageStateForGetSuggestion
                          )
                        }
                        renderSuggestion={(a) =>
                          myForm.renderSuggestion(a, info)
                        }
                        ref={(a) => myForm.storeInputReference(a, false)}
                        inputProps={{
                          placeholder: info["placeholder"],
                          value: String(myForm.pageState[info["name"]]),
                          onChange: (a, b) => {
                            myForm.onChangeAutoSuggest(a, b, info);
                          },
                          onBlur: () => myForm.onblurValidator(info),
                          onKeyPress: (a) =>
                            myForm.onKeyPressForKeyNav(a, info),
                          disabled: checkDisabledCondition(info),
                        }}
                      />
                    )}
                    {myForm.internalValidationErrors[info["name"]] && (
                      <p>{myForm.internalValidationErrors[info["name"]]}</p>
                    )}
                  </div>
                );
            })}
          </div>
          <div className="form-field-vertical_no_b">
            {groupInfo["group-6"].map(function (info) {
              if (info["type"] === "dropdown")
                return (
                  <div className={info["className"]} key={info["label"]}>
                    <label className={info["labelClassName"]}>
                      {info["label"]}
                    </label>

                    <select
                      className={info["inputClassName"]}
                      onChange={(newValue) => {
                        myForm.handleChangeForSelect(newValue, info["name"]);
                      }}
                      disabled={checkDisabledCondition(info)}
                      value={myForm.pageState[info["name"]]}
                      ref={(a) =>
                        myForm.storeInputReferenceForSelect(a, info["name"])
                      }
                      onKeyPress={(a) => myForm.onKeyPressForKeyNav(a, info)}
                    >
                      {info["dropdown_items"].map((dropdown_item) => (
                        <option
                          value={dropdown_item.value}
                          key={dropdown_item.label}
                        >
                          {dropdown_item.label}
                        </option>
                      ))}
                    </select>
                    {myForm.internalValidationErrors[info["name"]] && (
                      <p>{myForm.internalValidationErrors[info["name"]]}</p>
                    )}
                  </div>
                );
              else
                return (
                  <div className={info["className"]} key={info["label"]}>
                    {checkVisibilityCondition(info) && (
                      <label className={info["labelClassName"]}>
                        {info["label"]}
                      </label>
                    )}
                    {checkVisibilityCondition(info) && (
                      <Autosuggest
                        id={info["name"]}
                        // suggestions={myForm.suggestions}
                        suggestions = {(myForm.suggestions != undefined) ? myForm.suggestions : ""}  // changes made for removing error that was caused by undefined suggestion
                        onSuggestionsFetchRequested={(a) =>
                          myForm.onSuggestionsFetchRequested(a, (b) =>
                            myForm.suggestionFetchApi(info, b)
                          )
                        }
                        onSuggestionsClearRequested={() =>
                          myForm.onSuggestionsClearRequested(info)
                        }
                        getSuggestionValue={(a) =>
                          myForm.getSuggestionValue(
                            a,
                            info,
                            myForm.performSuggestions,
                            myForm.updatePageStateForGetSuggestion
                          )
                        }
                        renderSuggestion={(a) =>
                          myForm.renderSuggestion(a, info)
                        }
                        ref={(a) => myForm.storeInputReference(a, false)}
                        inputProps={{
                          placeholder: info["placeholder"],
                          value: String(myForm.pageState[info["name"]]),
                          onChange: (a, b) => {
                            myForm.onChangeAutoSuggest(a, b, info);
                          },
                          onBlur: () => myForm.onblurValidator(info),
                          onKeyPress: (a) =>
                            myForm.onKeyPressForKeyNav(a, info),
                          disabled: checkDisabledCondition(info),
                        }}
                      />
                    )}
                    {myForm.internalValidationErrors[info["name"]] && (
                      <p>{myForm.internalValidationErrors[info["name"]]}</p>
                    )}
                  </div>
                );
            })}
          </div>
          
          {/* <div className="field-container">
            {groupInfo["group-4"].map(function (info) {
              return (
                <div className={info["className"]} key={info["label"]}>
                  {checkVisibilityCondition(info) && (
                    <label className={info["labelClassName"]}>
                      {info["label"]}
                    </label>
                  )}
                  {checkVisibilityCondition(info) && (
                    <input
                      className={info["className"]}
                      type={info["type"]}
                      name={info["name"]}
                      placeholder={info["placeholder"]}
                      value={myForm.pageState[info["name"]]}
                      onChange={myForm.handleChange}
                      onBlur={() => {}}
                      disabled={true}
                    />
                  )}
                  {myForm.internalValidationErrors[info["name"]] && (
                    <p>{myForm.internalValidationErrors[info["name"]]}</p>
                  )}
                </div>
              );
            })}
          </div> */}
        </div>
        {/* <div className="table-container">
          <DynamicViewTable
            tableHeader={tripChallanTableHeader}
            tableItems={tripChallanTableItems}
            tableValues={myForm.pageState["temp"]}
            setPageStateByField={myForm.setPageStateByField}
            pageStateArray={myForm.pageState["temp"]}
            fieldMapping="temp"
          /> */}
        {/* </div> */}
        <div className="form-footer">
          <button
            onClick={() => {
              console.log("Values", myForm.pageState);
              console.log("Value verification", myForm.valueVerificationState);
              console.log("Id clearance", myForm.idClearancState);
              console.log("Reference store", myForm.refStoreObject.current);
              console.log("Session", sessionObject.sessionVariables);
              console.log("Page mode", myForm.pageMode);
            }}
            type="button"
            className="btn btn-primary"
          >
            Log
          </button>

          <button 
            className="btn btn-primary" 
            onClick={handlePrintBilty}
            disabled={!myForm.pageState?.bilty_id}
          >
            Print
          </button>

          {/* <button
            onClick={(e) => {
              myForm.setServerPrintNeeded(true);
              // myForm.handleSubmit(e);
            }}
            type="button"
            ref={(a) => myForm.storeInputReferenceForSelect(a, "print_button")}
            className="btn btn-primary"
          >
            Print
          </button> */}

          {/* <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={(myForm) => ComponentToPrint.componentRef}
          /> */}
          <div style={{ display: "none" }}>
            <ComponentToPrint
              fields={myForm.pageState}
              ref={(el) => (ComponentToPrint.componentRef = el)}
            />
          </div>

          <div className="status">{myForm.renderSubmitApiResponseStatus()}</div>
        </div>
      </div>
    </div>
  );
};

export default BiltyInquiryForm;
